import React from "react";

const Testimonials = ({
  author1Name = "John Doe",
  author1Src = "https://images.unsplash.com/photo-1521198022873-af0f772bf653?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
  author1Alt = "Image of John Doe",
  author1Position = "CEO, Company ABC",
  review1 = "Seamless LLC registration process. I had an excellent experience from start to finish.",

  author2Name = "Jane Smith",
  author2Src = "https://images.unsplash.com/photo-1695746999600-2e24d80b2932?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
  author2Alt = "Image of Jane Smith",
  author2Position = "Founder, Startup XYZ",
  review2 = "Highly professional service with excellent support. They made the entire process stress-free.",

  author3Name = "Michael Johnson",
  author3Src = "https://images.unsplash.com/photo-1634309490604-1270c0d486e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
  author3Alt = "Image of Michael Johnson",
  author3Position = "COO, Business123",
  review3 = "The registration was fast and efficient. Their team really pays attention to detail.",

  author4Name = "Sarah Williams",
  author4Src = "https://images.unsplash.com/photo-1611244420030-b22f359eee31?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
  author4Alt = "Image of Sarah Williams",
  author4Position = "Owner, SmallBiz Co.",
  review4 = "They handled everything with ease, leaving me free to focus on my business launch.",
  heading1 = "What Our Clients Say About Our Business Registration Services",
}) => {
  return (
    <div className="thq-section-padding">
      <div className="flex flex-col items-center font-general thq-section-max-width">
        <div className="flex flex-col items-center gap-2 max-w-[600px] mt-[-4%] mb-16 md:mb-12 sm:mb-6">
          <h2 className="thq-heading-2 text-center">{heading1}</h2>
        </div>
        <div className="thq-grid-2">
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card w-full sm:w-auto">
                <div className="flex items-center self-start gap-2">
                  <img
                    alt={author1Alt}
                    src={author1Src}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <div className="flex flex-col items-start justify-center">
                    <strong className="thq-body-large">{author1Name}</strong>
                    <span className="thq-body-small">{author1Position}</span>
                  </div>
                </div>
                <span className="thq-body-small text-left">{review1}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card w-full sm:w-auto">
                <div className="flex items-center self-start gap-2">
                  <img
                    alt={author2Alt}
                    src={author2Src}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <div className="flex flex-col items-start justify-center">
                    <strong className="thq-body-large">{author2Name}</strong>
                    <span className="thq-body-small">{author2Position}</span>
                  </div>
                </div>
                <span className="thq-body-small text-left">{review2}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card w-full sm:w-auto">
                <div className="flex items-center self-start gap-2">
                  <img
                    alt={author3Alt}
                    src={author3Src}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <div className="flex flex-col items-start justify-center">
                    <strong className="thq-body-large">{author3Name}</strong>
                    <span className="thq-body-small">{author3Position}</span>
                  </div>
                </div>
                <span className="thq-body-small text-left">{review3}</span>
              </div>
            </div>
          </div>
          <div className="thq-animated-card-bg-2">
            <div className="thq-animated-card-bg-1">
              <div data-animated="true" className="thq-card w-full sm:w-auto">
                <div className="flex items-center self-start gap-2">
                  <img
                    alt={author4Alt}
                    src={author4Src}
                    className="w-12 h-12 object-cover rounded-full"
                  />
                  <div className="flex flex-col items-start justify-center">
                    <strong className="thq-body-large">{author4Name}</strong>
                    <span className="thq-body-small">{author4Position}</span>
                  </div>
                </div>
                <span className="thq-body-small text-left">{review4}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
