import React, { useEffect, useState } from "react";

import CorporationImage from "./Images/Corporation.png";
import DBAImage from "./Images/DBA.png";
import LLCImage from "./Images/LLC.png";
import NonProfitImage from "./Images/NonProfitOrg.png";
import SoleProprietorshipImage from "./Images/SoleProprietorship.png";
import HelpMeDecide from "./Images/HelpMeDecide.png";
import { Link } from "react-router-dom";

const Hero = ({
  heading1 = "Launch Your Business Today – We Handle the Paperwork, You Build the Future",
  content1 = "Start with Confidence – Your Business, Our Expertise. Customized Solutions, 12-48 Hour Registration, and Support You Can Trust.",

  image1Src = DBAImage,
  image1Alt = "DBAImage",

  image2Src = SoleProprietorshipImage,
  image2Alt = "SoleProprietorshipImage",

  image3Src = NonProfitImage,
  image3Alt = "NonProfitImage",

  image4Src = CorporationImage,
  image4Alt = "Corporation Image",

  image5Src = LLCImage,
  image5Alt = "LLCImage",

  image6Src = HelpMeDecide,
  image6Alt = "Help Me Decide",

  get_started_button = "Get Started Now",
}) => {
  const [navbarHeight, setNavbarHeight] = useState(0);

  // Хук для вычисления высоты navbar и обновления отступа
  useEffect(() => {
    const updateNavbarHeight = () => {
      const navbar = document.querySelector("nav");
      const height = navbar ? navbar.offsetHeight : 0;
      setNavbarHeight(height);
    };

    // Вызываем при монтировании и при изменении размера окна
    updateNavbarHeight();
    window.addEventListener("resize", updateNavbarHeight);

    return () => {
      window.removeEventListener("resize", updateNavbarHeight);
    };
  }, []);

  const scrollToPrices = () => {
    const contactSection = document.getElementById("pricing_block");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <div
      className="gap-12 w-full h-auto flex overflow-hidden relative items-center flex-shrink-0 flex-col mx-auto font-general"
      style={{ paddingTop: `${navbarHeight}px` }}
    >
      <div className="gap-6 w-auto flex items-center flex-col pb-2 max-w-screen-xl mx-auto">
        <div className="gap-6 flex self-stretch items-center flex-col">
          <h1 className="text-center text-5xl text-neutralDark font-extrabold font-general leading-tight">
            {heading1}
          </h1>
          <p className="text-center text-xl font-general text-neutralDark leading-tight">
            {content1}
          </p>
        </div>
        <div className="gap-2 flex items-center pt-2">
          <button
            className="bg-primary1 text-white py-2 px-4 rounded thq-button-filled"
            onClick={scrollToPrices}
          >
            <span className="text-base font-general">{get_started_button}</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row items-center justify-center w-full max-w-[85%]">
        <div className="flex flex-col w-full md:w-1/2 gap-4 flex-grow-0">
          <div className="relative w-full h-[16vh] rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
            <img
              alt={image1Alt}
              src={String(image1Src)}
              className="w-full h-full object-cover"
            />
            <Link
              to="/dba"
              className="absolute bottom-[9%] left-[4%] bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal transform hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
            >
              Get Started
            </Link>
          </div>
          <div className="flex gap-2">
            <div className="relative w-full h-full rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
              <img
                alt={image2Alt}
                src={String(image2Src)}
                className="w-full object-cover rounded"
              />
              <Link
                to="/soleproprietorship"
                className="absolute bottom-[9%] left-[4%] bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal transform hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
              >
                Get Started
              </Link>
            </div>
            <div className="relative w-full h-full rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
              <img
                alt={image3Alt}
                src={String(image3Src)}
                className="w-full object-cover rounded"
              />
              <Link
                to="/nonprofitorganization"
                className="absolute bottom-[9%] left-[4%] bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal transform hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full md:w-1/2 gap-4 flex-grow-0">
          <div className="flex gap-2">
            <div className="relative w-full h-full rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
              <img
                alt={image4Alt}
                src={String(image4Src)}
                className="w-full object-cover rounded"
              />
              <Link
                to="/corporation"
                className="absolute bottom-[9%] left-[4%] bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal transform hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
              >
                Get Started
              </Link>
            </div>
            <div className="relative w-full h-full rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
              <img
                alt={image5Alt}
                src={String(image5Src)}
                className="w-full object-cover rounded"
              />
              <Link
                to="/llc"
                className="absolute bottom-[9%] left-[4%] bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal transform hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
              >
                Get Started
              </Link>
            </div>
          </div>
          <div className="relative w-full h-[16vh] rounded-lg overflow-hidden shadow-[4px_8px_16px_rgba(0,0,0,0.25)]">
            <img
              alt={image6Alt}
              src={String(image6Src)}
              className="w-full h-full object-cover"
            />
            <Link
              to="/"
              className="absolute top-[70%] left-1/2 transform -translate-x-1/2 bg-accent1 text-primary2 border-none py-[10px] px-[20px] text-base font-semibold rounded-lg cursor-pointer opacity-80 shadow-md transition-all duration-300 ease-in-out whitespace-normal hover:bg-accent2 hover:text-primary1 hover:opacity-100 hover:shadow-[0_6px_12px_rgba(0,0,0,0.15)] hover:scale-105 active:scale-95 active:shadow-[0_2px_4px_rgba(0,0,0,0.1)] focus:outline-none"
            >
              Help me decide
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
