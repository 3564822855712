import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

const Navbar = ({
  link1 = "Home",
  link2 = "Services",
  link3 = "Pricing",
  link4 = "Contact Us",
  link5 = "Learn More",
  logoSrc = "https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/84ec08e8-34e9-42c7-9445-d2806d156403/fac575ac-7a41-484f-b7ac-875042de11f8?org_if_sml=1&force_format=original",
  logoAlt = "LLC Registration Services",
}) => {
  const location = useLocation();
  const [servicesDropdown, setServicesDropdown] = useState(false);
  const dropdownRef = useRef(null);

  const handleHomeClick = () => {
    if (location.pathname === "/") {
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      window.location.href = "/";
    }
  };

  const toggleServicesDropdown = () => {
    setServicesDropdown((prev) => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setServicesDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const scrollToPricing = () => {
    const section = document.getElementById("pricing_block");
    if (section) section.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const scrollToContact = () => {
    const section = document.getElementById("contact_us_button");
    if (section)
      section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const scrollToFAQ = () => {
    const section = document.getElementById("faq_container");
    if (section)
      section.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const navbarTabClasses =
    "flex-1 min-w-[100px] h-14 flex items-center justify-center text-[18px] font-semibold rounded-[80px] cursor-pointer transition-colors duration-150 ease-in";

  return (
    <div className="w-full flex sticky top-0 bg-neutralLight shadow-[0_4px_5px_rgba(0,0,0,0.1)] transition-all duration-[5300ms] ease-in-out font-general items-center justify-center z-20">
      <div className="flex items-center px-12 py-2 justify-center font-general">
        <img alt={logoAlt} src={logoSrc} className="h-12" />
        <div className="flex">
          <nav className="gap-32 flex-1 flex items-center ml-8 flex-row justify-start font-general py-2 pr-6 pl-2 rounded-[80px] shadow-[0_6px_12px_rgba(24,94,224,0.25)]">
            <span
              className={navbarTabClasses}
              onClick={handleHomeClick}
              style={{
                background:
                  "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                backgroundClip: "text",
                backgroundSize: "200% 100%",
                backgroundPosition: "100%",
                WebkitTextFillColor: "transparent",
                transition: "background-position 300ms ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundPosition = "0 100%")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundPosition = "100%")
              }
            >
              {link1}
            </span>
            <div className="relative" ref={dropdownRef}>
              <span
                className={navbarTabClasses}
                onClick={toggleServicesDropdown}
                style={{
                  background:
                    "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                  backgroundClip: "text",
                  backgroundSize: "200% 100%",
                  backgroundPosition: "100%",
                  WebkitTextFillColor: "transparent",
                  transition: "background-position 300ms ease",
                }}
                onMouseEnter={(e) =>
                  (e.currentTarget.style.backgroundPosition = "0 100%")
                }
                onMouseLeave={(e) =>
                  (e.currentTarget.style.backgroundPosition = "100%")
                }
              >
                {link2}
                <FontAwesomeIcon
                  icon={faCaretDown}
                  className={`transition-transform duration-3000 ease pl-1 ${servicesDropdown ? "animate-bounce" : ""}`}
                />
              </span>
              <div
                className={`absolute top-full left-0 bg-neutralLight shadow-[0_8px_16px_rgba(0,0,0,0.15)] rounded-lg p-2 transition-opacity duration-300 ease transform-gpu ${
                  servicesDropdown
                    ? "opacity-100 translate-y-0 block"
                    : "opacity-0 translate-y-[-10px] hidden"
                } w-auto whitespace-nowrap`}
              >
                {[
                  { to: "/llc", label: "LLC" },
                  { to: "/corporation", label: "Corporation" },
                  {
                    to: "/nonprofitorganization",
                    label: "Non-profit Organization",
                  },
                  { to: "/soleproprietorship", label: "Sole Proprietorship" },
                  { to: "/dba", label: "DBA" },
                ].map((link) => (
                  <Link
                    key={link.to}
                    to={link.to}
                    className="block p-2 cursor-pointer transition-transform duration-200 ease rounded-lg"
                    style={{
                      background:
                        "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                      backgroundClip: "text",
                      backgroundSize: "200% 100%",
                      backgroundPosition: "100%",
                      WebkitTextFillColor: "transparent",
                      transition: "background-position 300ms ease",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.backgroundPosition = "0 100%")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.backgroundPosition = "100%")
                    }
                  >
                    {link.label}
                  </Link>
                ))}
              </div>
            </div>
            <span
              className={navbarTabClasses}
              onClick={scrollToPricing}
              style={{
                background:
                  "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                backgroundClip: "text",
                backgroundSize: "200% 100%",
                backgroundPosition: "100%",
                WebkitTextFillColor: "transparent",
                transition: "background-position 300ms ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundPosition = "0 100%")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundPosition = "100%")
              }
            >
              {link3}
            </span>
            <span
              className={navbarTabClasses}
              onClick={scrollToContact}
              style={{
                background:
                  "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                backgroundClip: "text",
                backgroundSize: "200% 100%",
                backgroundPosition: "100%",
                WebkitTextFillColor: "transparent",
                transition: "background-position 300ms ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundPosition = "0 100%")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundPosition = "100%")
              }
            >
              {link4}
            </span>
            <span
              className={`${navbarTabClasses}  whitespace-nowrap`}
              onClick={scrollToFAQ}
              style={{
                background:
                  "linear-gradient(to right, var(--color-theme-primary1) 50%, var(--color-theme-neutral-dark) 50%)",
                backgroundClip: "text",
                backgroundSize: "200% 100%",
                backgroundPosition: "100%",
                WebkitTextFillColor: "transparent",
                transition: "background-position 300ms ease",
              }}
              onMouseEnter={(e) =>
                (e.currentTarget.style.backgroundPosition = "0 100%")
              }
              onMouseLeave={(e) =>
                (e.currentTarget.style.backgroundPosition = "100%")
              }
            >
              {link5}
            </span>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
