import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faClipboardCheck,
  faCreditCard,
  faFilePen,
} from "@fortawesome/free-solid-svg-icons";

const Steps = ({
  headerTitle = "Easily Register Your Business in 4 Simple Steps",
  headerDescription = "Forming your business has never been easier! Whether it is an LLC, Corporation, DBA, or Sole Proprietorship, " +
    "you only need a few basic details – your preferred business name, contact information, and purpose of the business. No confusing paperwork, " +
    "no legal jargon. Just follow our guided steps and get your business entity registered in minutes! Additionally, " +
    "our dedicated support team is here to assist you throughout the process. We value your time and have " +
    "streamlined the registration process to be as straightforward as possible, ensuring you can focus on building " +
    "your business.",
  buttonText = "Get Started Today",
  step1Title = "Provide Basic Business Information",
  step1Description = "Enter your business name, business purpose, and contact details in our easy-to-use form. " +
    "This will take only a few minutes and will set you up for success by protecting your assets and business.",
  step2Title = "Review Your Details",
  step2Description = "Double-check all the details you provided and confirm the accuracy of the " +
    "information. Our experts are always ready to help if you have any questions.",
  step3Title = "Finalize Your Registration with Secure Payment",
  step3Description = "Complete the payment process through Stripe – fast, easy, and secure. " +
    "Your payment will initiate the registration process.",
  step4Title = "Receive Confirmation and Updates",
  step4Description = "Once your payment is confirmed, you’ll receive an email with your business registration " +
    "status and tracking details.",
}) => {
  const [progress, setProgress] = useState(0);
  const rightBlockRef = useRef(null);

  const steps = [
    { icon: faFilePen, label: "Billing Info" },
    { icon: faClipboardCheck, label: "Review Your Details" },
    { icon: faCreditCard, label: "Payment Method" },
    { icon: faCheckCircle, label: "Success" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!rightBlockRef.current) return;

      const { top, height } = rightBlockRef.current.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      // Точка начала отсчета (30% высоты окна)
      const startPoint = windowHeight * 0.3;

      // Конечная точка — когда видимыми остаются 95% блока
      const endVisibleHeight = height * 0.05;

      const rawProgress = Math.min(
        Math.max(
          (startPoint - (top - endVisibleHeight)) / (height - endVisibleHeight),
          0,
        ),
        1,
      );

      setProgress(rawProgress * 100);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="w-full mb-36 mt-[100px] flex relative justify-center items-center flex-col font-general thq-section-padding">
      <div className="w-full flex gap-16 items-start flex-col md:flex-row font-general thq-section-max-width">
        {/* Левый блок */}
        <div className="flex gap-6 flex-col items-start font-general mb-8 md:sticky md:top-[70px] md:self-start md:w-1/2">
          <h2 className="thq-heading-2">{headerTitle}</h2>
          <p className="thq-body-large">{headerDescription}</p>

          {/* Прогресс-бар с брейкпоинтами */}
          <div className="w-full max-w-full md:max-w-[calc(100%-45px)] bg-gray-200 rounded-full h-2 my-6 relative">
            <div
              className="bg-blue-500 h-2 rounded-full transition-all duration-300"
              style={{ width: `${progress}%` }}
            ></div>

            {/* Брейкпоинты */}
            {steps.map((step, index) => (
              <div
                key={index}
                className={`absolute top-1/2 transform -translate-y-1/2 w-9 h-9 rounded-full border-2 flex items-center justify-center ${
                  progress >= (index / (steps.length - 1)) * 100
                    ? index === steps.length - 1
                      ? "bg-green-500 border-green-500 text-white" // Зеленый цвет для последнего шага
                      : "bg-blue-500 border-blue-500 text-white"
                    : "bg-white border-gray-300 text-gray-500"
                }`}
                style={{
                  left: `${(index / (steps.length - 1)) * 100}%`,
                }}
              >
                <FontAwesomeIcon icon={step.icon} className="text-lg" />
              </div>
            ))}
          </div>

          {/* Кнопка Get Start */}
          <div className="flex justify-center items-center w-full h-full">
            <button className="thq-button-animated thq-button-filled steps-button">
              <span className="thq-body-small">{buttonText}</span>
            </button>
          </div>
        </div>

        {/* Правый блок */}
        <div
          className="flex flex-col font-general md:w-1/2"
          ref={rightBlockRef}
        >
          <div className="sticky top-[10%] transform rotate-[-2deg] mb-8 bg-accent1 font-general thq-card w-full md:w-auto">
            <h2 className="thq-heading-2">{step1Title}</h2>
            <span className="text-center thq-body-small">
              {step1Description}
            </span>
            <label className="absolute top-2 right-2 text-[40px] font-bold font-general thq-heading-3">
              01
            </label>
          </div>
          <div className="sticky top-[10%] transform rotate-[2deg] mb-8 bg-accent2 font-general thq-card w-full md:w-auto">
            <h2 className="thq-heading-2">{step2Title}</h2>
            <span className="text-center thq-body-small">
              {step2Description}
            </span>
            <label className="absolute top-2 right-2 text-[40px] font-bold font-general thq-heading-3">
              02
            </label>
          </div>
          <div className="sticky top-[10%] transform rotate-[-2deg] mb-8 bg-accent1 font-general thq-card w-full md:w-auto">
            <h2 className="thq-heading-2">{step3Title}</h2>
            <span className="text-center thq-body-small">
              {step3Description}
            </span>
            <label className="absolute top-2 right-2 text-[40px] font-bold font-general thq-heading-3">
              03
            </label>
          </div>
          <div className="sticky top-[10%] transform rotate-[2deg] mb-8 bg-accent2 font-general thq-card w-full md:w-auto">
            <h2 className="thq-heading-2">{step4Title}</h2>
            <span className="text-center thq-body-small">
              {step4Description}
            </span>
            <label className="absolute top-2 right-2 text-[40px] font-bold font-general thq-heading-3">
              04
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Steps;
