import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

const Faq = () => {
  const faqs = [
    {
      title: "What is a DBA (Doing Business As)?",
      content:
        "A DBA allows a business to operate under a different name than its legal registered name. It’s useful for branding without forming a new entity.",
    },
    {
      title: "What is the difference between an LLC and a Corporation?",
      content:
        "The main differences are in taxation, ownership structure, and management flexibility. LLCs offer pass-through taxation, while corporations can issue shares to raise capital.",
    },
    {
      title: "How much does it cost to start a DBA?",
      content:
        "The cost varies by state and county but typically ranges between $10-$100. Additional fees may apply for name searches or publication requirements.",
    },
    {
      title: "Do I need an EIN (Employer Identification Number)?",
      content:
        "Yes, most businesses need an EIN for tax purposes, hiring employees, and opening a business bank account.",
    },
    {
      title: "What is the benefit of forming an LLC?",
      content:
        "An LLC provides personal liability protection, separating your personal assets from business debts and obligations.",
    },
    {
      title: "Can I change my business structure later?",
      content:
        "Yes, you can convert an LLC to a corporation or vice versa. However, the process and requirements vary by state.",
    },
    {
      title: "Do I need to renew my LLC annually?",
      content:
        "Yes, most states require an annual report and renewal fee to keep your LLC in good standing.",
    },
    {
      title: "Can I have multiple DBAs under one LLC?",
      content:
        "Yes, you can operate multiple DBAs under the same LLC to manage different brands or business lines.",
    },
    {
      title: "What is a registered agent, and why do I need one?",
      content:
        "A registered agent is responsible for receiving legal documents on behalf of your business. It ensures you don’t miss important notices.",
    },
    {
      title: "Can I use my home address as my business address?",
      content:
        "Yes, in most states you can, but it may affect your privacy as business addresses are often public records.",
    },
    {
      title: "How do I open a business bank account?",
      content:
        "To open a business bank account, you’ll need your EIN, business formation documents, and an operating agreement.",
    },
    {
      title: "What is an operating agreement?",
      content:
        "An operating agreement is an internal document outlining the ownership and management structure of an LLC.",
    },
    {
      title: "Can I operate my business without a business license?",
      content:
        "Most businesses require some form of licensing or permits to operate legally. The requirements vary by industry and location.",
    },
    {
      title: "Do I need business insurance?",
      content:
        "While not always mandatory, business insurance protects against risks like lawsuits and property damage.",
    },
    {
      title: "How do I name my business?",
      content:
        "Your business name should be unique and align with your brand. Some states require name availability searches before registration.",
    },
    {
      title: "What is a C Corporation?",
      content:
        "A C Corporation is a legal structure where the business is taxed separately from its owners, with the ability to raise capital through shares.",
    },
    {
      title: "What is an S Corporation?",
      content:
        "An S Corporation offers pass-through taxation, like an LLC, but with some of the benefits of a corporation, such as limited liability.",
    },
    {
      title: "Can a non-US citizen form an LLC or Corporation?",
      content:
        "Yes, non-US citizens can register LLCs or Corporations, though some states may have specific requirements.",
    },
    {
      title: "Do I need to publish a notice for my LLC?",
      content:
        "Some states, like New York, require LLCs to publish a notice in local newspapers to complete the formation process.",
    },
    {
      title: "Can I start an LLC with just one person?",
      content:
        "Yes, single-member LLCs are allowed in all states, providing the same benefits as multi-member LLCs.",
    },
    {
      title: "What happens if I don’t pay my business taxes on time?",
      content:
        "Late tax payments can result in penalties, interest, and possible suspension of your business license or registration.",
    },
    {
      title: "How do I dissolve my business if I no longer want to operate?",
      content:
        "You’ll need to file dissolution documents with the state and notify any relevant agencies, including the IRS.",
    },
    {
      title: "How do I manage taxes for my LLC?",
      content:
        "LLCs are typically taxed as pass-through entities, meaning profits are reported on the owners’ personal tax returns.",
    },
    {
      title: "Can I register a non-profit organization through your service?",
      content:
        "Yes, we can assist with the formation of non-profit organizations, including filing for tax-exempt status with the IRS.",
    },
    {
      title: "What is the difference between a sole proprietorship and an LLC?",
      content:
        "A sole proprietorship offers no legal separation between the owner and the business, unlike an LLC, which provides liability protection.",
    },
    {
      title: "Do I need a business plan to start an LLC?",
      content:
        "While not legally required, a business plan helps guide your business strategy and can be useful for securing funding.",
    },
    {
      title: "Can I operate my LLC in multiple states?",
      content:
        "Yes, but you’ll need to register your LLC as a foreign entity in each state where you do business.",
    },
    {
      title: "How do I get an EIN for my business?",
      content:
        "You can apply for an EIN directly through the IRS website. It’s usually issued immediately after the application is submitted.",
    },
  ];

  const leftBlockContent = {
    heading: (
      <>
        Any questions?
        <br />
        We got you.
      </>
    ),
    text: "We are here to answer all your questions. Below, you'll find some of the most frequently asked questions about our services.",
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFaq = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="py-12">
      <div className="grid grid-cols-12 gap-12 max-w-component-large mx-auto px-6 lg:px-96 items-start">
        <div className="col-span-12 lg:col-span-5 pr-0 lg:pr-8 text-left">
          <h1 className="text-4xl lg:text-5xl leading-tight mb-6">
            {leftBlockContent.heading}
          </h1>
          <p className="text-base lg:text-lg leading-relaxed">
            {leftBlockContent.text}
          </p>
        </div>
        <div className="col-span-12 lg:col-span-7">
          <div
            className="flex flex-col overflow-hidden max-h-[600px] hover:overflow-y-auto scrollbar"
            id="faq_container"
          >
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="w-full cursor-pointer transition-colors duration-300 border-b border-gray-800"
                onClick={() => toggleFaq(index)}
              >
                <div className="p-6 flex justify-between items-center">
                  <h2 className="text-lg lg:text-xl text-gray-800 m-0 font-semibold">
                    {faq.title}
                  </h2>
                  <span
                    className={`w-6 h-6 flex items-center justify-center border-2 border-gray-800 rounded-full transition-transform duration-300 ease-in-out shadow-md ${
                      activeIndex === index ? "transform rotate-180" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={activeIndex === index ? faMinus : faPlus}
                    />
                  </span>
                </div>
                <div
                  className={`overflow-hidden max-h-0 px-6 text-base lg:text-lg leading-relaxed transition-all duration-500 ease-in-out ${
                    activeIndex === index ? "max-h-screen py-2" : ""
                  }`}
                >
                  <p>{faq.content}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
