import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

const Features1 = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [previousTab, setPreviousTab] = useState(null);
  const [direction, setDirection] = useState("next");
  const [isHovered, setIsHovered] = useState(false);
  const intervalRef = useRef(null);

  const reasons = [
    {
      title: "Save Time and Focus on What Matters",
      description:
        "We’ll take care of all the paperwork, so you can focus on growing your business.",
    },
    {
      title: "Expert Assistance You Can Trust",
      description:
        "Our team of professionals ensures your LLC is set up correctly the first time.",
    },
    {
      title: "Customized Solutions for Every Business",
      description:
        "Whether you're starting small or expanding, we tailor our services to meet your unique needs.",
    },
    {
      title: "Fast, Simple, and Affordable LLC Formation",
      description:
        "Get your business registered quickly and easily, with transparent pricing and no hidden fees.",
    },
    {
      title: "Make Your Business Dreams a Reality",
      description:
        "We provide you with the support you need to turn your vision into a successful venture.",
    },
    {
      title: "Hassle-Free Business Setup",
      description:
        "Get started on your journey with ease, leaving the complicated setup to us.",
    },
    {
      title: "Your Business, Our Priority",
      description:
        "Our commitment is to see your business flourish with comprehensive support and guidance.",
    },
  ];

  const tabsLength = reasons.length;

  const startAutoScroll = () => {
    intervalRef.current = setInterval(() => {
      const nextIndex = (activeTab + 1) % tabsLength;
      changeTab(nextIndex, "next");
    }, 3000);
  };

  const stopAutoScroll = () => {
    clearInterval(intervalRef.current);
  };

  const changeTab = (newIndex, dir) => {
    setDirection(dir);
    setPreviousTab(activeTab);
    setActiveTab(newIndex);
  };

  useEffect(() => {
    startAutoScroll();
    return () => stopAutoScroll();
  }, [activeTab, tabsLength]);

  const handleMouseEnter = () => {
    stopAutoScroll();
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    startAutoScroll();
    setIsHovered(false);
  };

  const baseItemClass =
    "absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center transition-all pointer-events-none font-sans";

  const getItemClass = (index) => {
    let classList = [baseItemClass];

    if (activeTab === index) {
      classList.push(
        "opacity-100",
        "transform",
        "scale-[1.5]",
        "translate-x-0",
        "duration-[1000ms]",
        "ease",
      );
      if (isHovered) {
        classList.push(
          "scale-[1.9]",
          "bg-gradient-to-br",
          "from-[#ffffff]",
          "to-[#f0f0f0]",
          "rounded-[12px]",
          "overflow-hidden",
          "outline-none",
        );
      }
    } else if (previousTab === index) {
      classList.push(
        "opacity-0",
        "transform",
        "scale-[0.95]",
        "duration-[1000ms]",
        "ease",
      );
      if (direction === "next") {
        classList.push("translate-x-[-100%]");
      } else {
        classList.push("translate-x-full");
      }
    } else {
      classList.push(
        "opacity-0",
        "transform",
        "translate-x-full",
        "duration-[600ms]",
        "ease-in-out",
      );
    }
    return classList.join(" ");
  };

  const getItemStyle = (index) => {
    if (activeTab === index && isHovered) {
      return {
        transform: "scale(1.9) rotateY(3deg)",
        transition:
          "transform 0.6s ease, box-shadow 0.6s ease, background 0.6s ease",
      };
    }
    return {};
  };

  return (
    <div
      className="flex flex-col items-center justify-start h-[400px] w-full overflow-hidden bg-accent1 relative p-[20px] mt-[100px] font-sans"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="relative w-full h-full">
        {reasons.map((reason, index) => (
          <div
            key={index}
            className={getItemClass(index)}
            style={getItemStyle(index)}
          >
            <h2>{reason.title}</h2>
            <p>{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

Features1.propTypes = {
  reasons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
    }),
  ),
};

export default Features1;
