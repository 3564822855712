import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Contact = ({
  content1 = "Have questions or need assistance? Reach out to us!",
  content2 = "Our team is here to help you with any inquiries you may have.",
  email1 = "info@outlook.com",
  heading1 = "Contact Us",
}) => {
  return (
    <div
      id="contact_us_button"
      className="flex flex-col items-center justify-center gap-[12px] p-16 max-w-[600px] text-center mt-[40px] lg:mt-[-50px] mx-auto font-sans"
    >
      <h2 className="text-[24px] font-bold mb-8">{heading1}</h2>
      <p className="text-[19px] text-neutralDark mb-16">
        {content1} {content2}
      </p>

      <div className="flex items-center gap-8">
        <FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "1.55rem" }} />
        <a
          href={`mailto:${email1}`}
          className="font-bold text-[22px] text-neutralDark no-underline hover:underline font-sans"
        >
          {email1}
        </a>
      </div>
    </div>
  );
};

export default Contact;
