import React from "react";
import "./NonProfitOrganization.css";
import { Helmet } from "react-helmet";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const NonProfitOrganizationPage = () => {
  return (
    <div className="NonProfitOrganization-Page-container">
      <Helmet>
        <title>Test site llc</title>
      </Helmet>
      <Navbar></Navbar>
      <Footer></Footer>
    </div>
  );
};

export default NonProfitOrganizationPage;
