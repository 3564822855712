import React from "react";

import { Helmet } from "react-helmet";

import Navbar from "../components/common/Navbar";
import Hero from "../components/HomePage/Hero";
import Features1 from "../components/HomePage/Features1";
import CallToAction from "../components/HomePage/CallToAction";
import Features2 from "../components/HomePage/Features2";
import Pricing from "../components/HomePage/Pricing";
import Steps from "../components/HomePage/Steps";
import Testimonials from "../components/HomePage/Testimonials";
import Contact from "../components/HomePage/Contact";
import Footer from "../components/common/Footer";
import Faq from "../components/HomePage/Faq";
import OurRecommendations from "../components/HomePage/OurRecommendations";

const HomePage = () => {
  return (
    <div className="w-full flex flex-col min-h-screen items-center">
      <Helmet>
        <title>Test site llc</title>
      </Helmet>
      <Navbar></Navbar>
      <Hero></Hero>
      <Features1></Features1>
      <Steps></Steps>
      <Features2></Features2>
      <Pricing></Pricing>
      <Testimonials></Testimonials>
      <Faq></Faq>
      <OurRecommendations></OurRecommendations>
      <CallToAction></CallToAction>
      <Contact></Contact>
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
