import { createRoot } from "react-dom/client";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";

import "./index.css";
import NotFound from "./pages/NotFound";
import HomePage from "./pages/HomePage";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";
import LLCPage from "./pages/Services/LLCPage";
import CorporationPage from "./pages/Services/Corporation";
import DBAPage from "./pages/Services/DBA";
import NonProfitOrganizationPage from "./pages/Services/NonProfitOrganization";
import SoleProprietorshipPage from "./pages/Services/SoleProprietorship";

const Index = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/not-found" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/not-found" replace />} />

      {/*Services*/}
      <Route path="/llc" element={<LLCPage />} />
      <Route path="/corporation" element={<CorporationPage />} />
      <Route
        path="/nonprofitorganization"
        element={<NonProfitOrganizationPage />}
      />
      <Route path="/soleproprietorship" element={<SoleProprietorshipPage />} />
      <Route path="/dba" element={<DBAPage />} />
    </Routes>
  );
};

// Use createRoot instead of ReactDOM.render
const container = document.getElementById("app");
const root = createRoot(container);
root.render(
  <Router>
    <DevSupport
      ComponentPreviews={ComponentPreviews}
      useInitialHook={useInitial}
    >
      <Index />
    </DevSupport>
  </Router>,
);
