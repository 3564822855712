import React from "react";
import "./SoleProprietorship.css";
import { Helmet } from "react-helmet";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";

const SoleProprietorshipPage = () => {
  return (
    <div className="SoleProprietorship-Page-container">
      <Helmet>
        <title>Test site llc</title>
      </Helmet>
      <Navbar></Navbar>
      <Footer></Footer>
    </div>
  );
};

export default SoleProprietorshipPage;
