import React from "react";

const LLCInfo = ({
  sections = [
    {
      title: "What is an LLC?",
      text: "A limited liability company (LLC) is a type of business entity you can register in your state. The main purpose of an LLC company is to limit the personal liability of its owners—like a C or S corporation—but it also allows the business to operate with simpler rules and more flexible tax requirements.",
      imgSrc:
        "https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "Do I need an LLC?",
      text: "An LLC isn't always required, but many small business owners choose to form an LLC for personal liability protection. Having an LLC can also help you open bank accounts, enter into contracts, hire employees, and get necessary business licenses and permits.",
      imgSrc:
        "https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "What does LLC formation mean?",
      text: "When you form an LLC, you submit a specific set of business forms to the state where you wish to create a new business entity. Once it's approved, you can use this separate entity to record business expenses, take on business debts, file taxes, obtain business licenses, and more—and this is what gives you liability protection.",
      imgSrc:
        "https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
    },
    {
      title: "How easy is it to form an LLC?",
      text: "You may be a little intimidated by the idea of forming a legal entity like an LLC, especially if it's your first time. All you need is an understanding of what your business will do, how you plan to run it, and your state's LLC filing fees. Even if it's new territory, forming an LLC is straightforward.",
      imgSrc:
        "https://images.unsplash.com/photo-1511632765486-a01980e01a18?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3w5MTMyMXwwfDF8cmFuZG9tfHx8fHx8fHx8MTcyODg0OTA0MHw&ixlib=rb-4.0.3&q=80&w=1080",
    },
  ],
}) => {
  return (
    <div className="font-general text-neutralDark bg-neutralLight w-full mx-auto px-5 md:px-6 bd:max-w-[1272px] py-9 bd:py-12">
      {/* Заголовок страницы */}
      <div className="text-center mt-[-100px] mb-[30px]">
        <h1 className="text-4xl font-bold">
          What you need to know about forming an LLC
        </h1>
      </div>

      {/* Первый блок информации */}
      <div className="w-[90%] mx-auto mb-[30px] p-[30px] bg-accent1 rounded-[16px] shadow-custom leading-[1.6]">
        <div className="flex flex-col md:flex-row items-center bg-accent1 rounded-12 relative overflow-hidden p-[25px]">
          <div className="w-full md:w-[60%] py-[20px] px-0 md:py-0 md:px-[30px] text-[0.95em] leading-[1.6]">
            <h2 className="mt-[8px] text-[2em] tracking-[1px]">
              {sections[0].title}
            </h2>
            <p className="leading-[1.8] text-base">{sections[0].text}</p>
            <h2 className="mt-[8px] text-[2em] tracking-[1px]">
              {sections[1].title}
            </h2>
            <p className="leading-[1.8] text-base">{sections[1].text}</p>
          </div>
          <img
            src={sections[0].imgSrc}
            alt="LLC Information"
            className="w-full md:w-[40%] h-auto object-cover rounded-12"
          />
        </div>
      </div>

      {/* Второй блок информации */}
      <div className="w-[90%] mx-auto mb-[30px] p-[30px] bg-accent1 rounded-[16px] shadow-custom leading-[1.6]">
        <div className="flex flex-col md:flex-row items-center bg-accent1 rounded-12 relative overflow-hidden p-[25px]">
          <img
            src={sections[2].imgSrc}
            alt="LLC Formation"
            className="w-full md:w-[40%] h-auto object-cover rounded-12"
          />
          <div className="w-full md:w-[60%] py-[20px] px-0 md:py-0 md:px-[30px] text-[0.95em] leading-[1.6]">
            <h2 className="mt-[8px] text-[2em] tracking-[1px]">
              {sections[2].title}
            </h2>
            <p className="leading-[1.8] text-base">{sections[2].text}</p>
            <h2 className="mt-[8px] text-[2em] tracking-[1px]">
              {sections[3].title}
            </h2>
            <p className="leading-[1.8] text-base">{sections[3].text}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LLCInfo;
