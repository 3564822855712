import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCcPaypal,
  faCcStripe,
  faCcAmex,
  faCcDiscover,
  faCcVisa,
  faCcMastercard,
  faAmazon,
  faShopify,
} from "@fortawesome/free-brands-svg-icons";
import "./OurRecommendations.css";

const ourRecommendations = [
  { name: "PayPal", icon: faCcPaypal },
  { name: "Stripe", icon: faCcStripe },
  { name: "American Express", icon: faCcAmex },
  { name: "Discover", icon: faCcDiscover },
  { name: "Visa", icon: faCcVisa },
  { name: "MasterCard", icon: faCcMastercard },
  { name: "Amazon", icon: faAmazon },
  { name: "Shopify", icon: faShopify },
];

const OurRecommendations = () => {
  return (
    <div className="pt-20 pb-36 overflow-hidden">
      <div className="max-w-component-large mx-auto gap-8">
        <h2 className="text-center text-[24px] font-bold font-general mb-12">
          Trusted Tools for Your Business Journey:
        </h2>
        <div className="relative w-full overflow-hidden">
          <div className="flex gap-12 partners-animate-scroll">
            {/* Дублируем иконки для плавного бесконечного скролла */}
            {Array(3)
              .fill(ourRecommendations)
              .flat()
              .map((partner, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-40 h-32 p-6 bg-neutralLight rounded-6 shadow-md hover:shadow-lg transform hover:scale-105 transition-all duration-300 flex items-center justify-center"
                >
                  <FontAwesomeIcon icon={partner.icon} size="3x" />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurRecommendations;
