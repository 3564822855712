import React from "react";

const CallToAction = ({
  heading1 = "Start Your Business with Ease. We Handle the Paperwork, You Build Your Future.",
  content1 = "Get your LLC registered in just a few simple steps, backed by expert assistance and transparent pricing.",
}) => {
  return (
    <div className="pt-30 pb-36">
      <div className="max-w-component-large mx-auto">
        <div className="flex gap-24 transform rotate-[1deg] items-center justify-between rounded-8 bg-accent2 font-general transition-transform duration-300 hover:scale-110">
          <div className="w-full flex transform rotate-[-2deg] items-center justify-between rounded-8 bg-accent1 font-general">
            <div className="w-full flex transform rotate-[1deg] transition duration-300 items-center py-96 px-96 rounded-8 gap-48 hover:text-neutralLight hover:bg-neutralDark font-sans">
              <div className="flex flex-col items-center justify-center gap-24 text-center font-sans">
                <span className="text-[24px] font-bold">{heading1}</span>
                <p className="text-[19px]">{content1}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
