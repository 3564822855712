import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import "./Pricing.css";

export const GreenCheckIcon = (
  <FontAwesomeIcon
    icon={faCheckCircle}
    className="text-green-600 rounded-full pt-1"
  />
);

const Pricing = () => {
  let heading1 = "Choose your plan that fits your needs:";
  const [selectedOption, setSelectedOption] = useState("LLC");

  // Данные для разных типов организаций
  const pricingData = {
    LLC: {
      planFeeText: "+ state filling fee",
      plan1: "Basic plan",
      plan1Price: "$99",
      plan1Action: "Get Started Now",
      plan1Features: [
        "LLC Name Availability Check",
        "Articles of Organization Filing with State",
        "Registered Agent Service",
      ],
      plan1Description:
        "Perfect for small businesses just starting out. Everything you need to officially register your LLC.",
      plan2: "Growth Plan",
      plan2Price: "$199",
      plan2Action: "Get Started Now",
      plan2Features: [
        "Everything in Basic Plan",
        "EIN/Tax ID Number Obtainment",
        "Operating Agreement Drafting",
        "BOI Report Filing with FinCEN",
      ],
      plan2Description:
        "Ideal for growing businesses that need more than just basic registration. Stay compliant and ready to scale.",
      plan3: "Premium plan",
      plan3Price: "$299",
      plan3Action: "Get Started Now",
      plan3Features: [
        "Everything in Growth Plan",
        "Customized LLC Operating Agreement",
        "Priority Rush Filing",
        "BOI Report Filing with FinCEN",
        "Virtual Business Address + Contract (30 Days Free)",
        "Virtual Phone Number (30 Days Free)",
      ],
      plan3Description:
        "Designed for businesses that demand the best. Fast filing, personalized support, and premium features.",
    },
    Corporation: {
      planFeeText: "+ state filling fee",
      plan1: "Starter Plan",
      plan1Price: "$129",
      plan1Action: "Get Started Now",
      plan1Features: [
        "Business Name Availability Check",
        "Articles of Incorporation Filing",
        "Registered Agent Service",
      ],
      plan1Description:
        "Essential services to get your corporation officially registered.",
      plan2: "Professional Plan",
      plan2Price: "$229",
      plan2Action: "Get Started Now",
      plan2Features: [
        "Everything in Starter Plan",
        "EIN/Tax ID Number Obtainment",
        "Corporate Bylaws Drafting",
        "BOI Report Filing with FinCEN",
      ],
      plan2Description:
        "For corporations ready to establish a strong foundation and comply with legal requirements.",
      plan3: "Enterprise Plan",
      plan3Price: "$329",
      plan3Action: "Get Started Now",
      plan3Features: [
        "Everything in Professional Plan",
        "Customized Corporate Bylaws",
        "Priority Rush Filing",
        "Virtual Business Address + Contract (30 Days Free)",
        "Virtual Phone Number (30 Days Free)",
      ],
      plan3Description:
        "Comprehensive package for corporations seeking premium services and expedited processing.",
    },
    "Non-profit Organization": {
      planFeeText: "+ state filling fee",
      plan1: "Foundation Plan",
      plan1Price: "$149",
      plan1Action: "Get Started Now",
      plan1Features: [
        "Name Availability Check",
        "Articles of Incorporation Filing",
        "Registered Agent Service",
      ],
      plan1Description:
        "Basic services to establish your non-profit organization.",
      plan2: "Advancement Plan",
      plan2Price: "$249",
      plan2Action: "Get Started Now",
      plan2Features: [
        "Everything in Foundation Plan",
        "EIN/Tax ID Number Obtainment",
        "Bylaws Drafting",
        "BOI Report Filing with FinCEN",
      ],
      plan2Description:
        "Enhanced package to ensure compliance and proper setup of your non-profit.",
      plan3: "Impact Plan",
      plan3Price: "$349",
      plan3Action: "Get Started Now",
      plan3Features: [
        "Everything in Advancement Plan",
        "501(c)(3) Application Preparation",
        "Priority Rush Filing",
        "Virtual Business Address + Contract (30 Days Free)",
        "Virtual Phone Number (30 Days Free)",
      ],
      plan3Description:
        "Complete solution for non-profits aiming for tax-exempt status and immediate impact.",
    },
    "Sole Proprietorship": {
      planFeeText: "+ state filling fee",
      plan1: "Essential Plan",
      plan1Price: "$79",
      plan1Action: "Get Started Now",
      plan1Features: [
        "Business Name Search",
        "DBA Filing",
        "Registered Agent Service",
      ],
      plan1Description:
        "Basic essentials to operate your sole proprietorship under a business name.",
      plan2: "Enhanced Plan",
      plan2Price: "$179",
      plan2Action: "Get Started Now",
      plan2Features: [
        "Everything in Essential Plan",
        "EIN/Tax ID Number Obtainment",
        "Business License Research",
        "BOI Report Filing with FinCEN",
      ],
      plan2Description:
        "For sole proprietors who need additional compliance and tax setup.",
      plan3: "Complete Plan",
      plan3Price: "$279",
      plan3Action: "Get Started Now",
      plan3Features: [
        "Everything in Enhanced Plan",
        "Customized Business Documents",
        "Virtual Business Address + Contract (30 Days Free)",
        "Virtual Phone Number (30 Days Free)",
      ],
      plan3Description:
        "All-inclusive package for sole proprietors seeking comprehensive services.",
    },
    DBA: {
      planFeeText: "+ state filling fee",
      plan1: "Basic DBA Plan",
      plan1Price: "$59",
      plan1Action: "Get Started Now",
      plan1Features: [
        "DBA Name Search",
        "DBA Registration Filing",
        "Registered Agent Service",
      ],
      plan1Description:
        "Get your 'Doing Business As' name registered quickly and easily.",
      plan2: "Standard DBA Plan",
      plan2Price: "$159",
      plan2Action: "Get Started Now",
      plan2Features: [
        "Everything in Basic DBA Plan",
        "EIN/Tax ID Number Obtainment",
        "Publication Services (if required)",
        "BOI Report Filing with FinCEN",
      ],
      plan2Description:
        "Ideal for businesses needing additional compliance when registering a DBA.",
      plan3: "Premium DBA Plan",
      plan3Price: "$259",
      plan3Action: "Get Started Now",
      plan3Features: [
        "Everything in Standard DBA Plan",
        "Customized Business Contracts",
        "Virtual Business Address + Contract (30 Days Free)",
        "Virtual Phone Number (30 Days Free)",
      ],
      plan3Description:
        "For businesses seeking a complete package when operating under a new name.",
    },
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Получаем данные для выбранного типа организации
  const {
    planFeeText,
    plan1,
    plan1Price,
    plan1Action,
    plan1Features,
    plan1Description,
    plan2,
    plan2Price,
    plan2Action,
    plan2Features,
    plan2Description,
    plan3,
    plan3Price,
    plan3Action,
    plan3Features,
    plan3Description,
  } = pricingData[selectedOption];

  return (
    <div id="pricing_block" className="pricing-pricing23 thq-section-padding">
      <div className="pricing-max-width thq-section-max-width">
        <div className="pricing-section-title">
          <div className="pricing-content">
            <h2 className="thq-heading-2">{heading1}</h2>
            <div className="pricing-selector">
              {[
                "LLC",
                "Corporation",
                "Non-profit Organization",
                "Sole Proprietorship",
                "DBA",
              ].map((option) => (
                <div
                  key={option}
                  className={`option ${selectedOption === option ? "active" : ""}`}
                  onClick={() =>
                    handleOptionChange({ target: { value: option } })
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="pricing-container1">
          {/* План 1 */}
          <div className="pricing-column1 thq-card">
            <div className="pricing-price10">
              <div className="pricing-price11">
                <p className="pricing-text17 thq-body-large">{plan1}</p>
                <h3 className="pricing-text18 thq-heading-3">
                  {plan1Price}
                  <span className="state-filling-fee">{planFeeText}</span>
                </h3>
              </div>
              <div className="pricing-list1">
                {plan1Features.map((feature, index) => (
                  <div key={index} className={`pricing-list-item${10 + index}`}>
                    {GreenCheckIcon}
                    <span className="thq-body-small">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <p className="pricing-plan-description">{plan1Description}</p>
            <button className="pricing-button14 thq-button-outline thq-button-animated">
              <span className="thq-body-small">{plan1Action}</span>
            </button>
          </div>

          {/* План 2 */}
          <div className="pricing-column2 thq-card">
            <div className="pricing-price12">
              <div className="pricing-price13">
                <p className="pricing-text24 thq-body-large">{plan2}</p>
                <h3 className="pricing-text25 thq-heading-3">
                  {plan2Price}
                  <span className="state-filling-fee">{planFeeText}</span>
                </h3>
              </div>
              <div className="pricing-list2">
                {plan2Features.map((feature, index) => (
                  <div key={index} className={`pricing-list-item${13 + index}`}>
                    {GreenCheckIcon}
                    <span className="thq-body-small">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <p className="pricing-plan-description">{plan2Description}</p>
            <button className="pricing-button15 thq-button-outline thq-button-animated">
              <span className="thq-body-small">{plan2Action}</span>
            </button>
          </div>

          {/* План 3 */}
          <div className="pricing-column3 thq-card">
            <div className="pricing-price14">
              <div className="pricing-price15">
                <p className="pricing-text32 thq-body-large">{plan3}</p>
                <h3 className="pricing-text33 thq-heading-3">
                  {plan3Price}
                  <span className="state-filling-fee">{planFeeText}</span>
                </h3>
              </div>
              <div className="pricing-list3">
                {plan3Features.map((feature, index) => (
                  <div key={index} className={`pricing-list-item${17 + index}`}>
                    {GreenCheckIcon}
                    <span className="thq-body-small">{feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <p className="pricing-plan-description">{plan3Description}</p>
            <button className="pricing-button16 thq-button-outline thq-button-animated">
              <span className="thq-body-small">{plan3Action}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
