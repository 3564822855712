import React from "react";

import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faLinkedin,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = ({
  column2Title = "Services",
  link7 = "Blog",
  link5 = "Terms of Service",
  link8 = "Testimonials",
  action1 = "Sign Up",
  content3 = "Disclaimer: This website is not a substitute for legal advice. Consult with a licensed attorney for proper guidance.",
  link4 = "FAQs",
  logoSrc = "https://presentation-website-assets.teleporthq.io/logos/logo.png",
  cookiesLink = "/cookie-policy",
  content2 = "&copy; 2023 LLC Registration Services. All rights reserved.",
  link9 = "Payment Options",
  link6 = "Privacy Policy",
  logoAlt = "LLC Registration Services Logo",
  link1 = "About Us",
  privacyLink = "/privacy-policy",
  link10 = "Support",
  column1Title = "Company",
  termsLink = "/terms-of-service",
  link3 = "Contact Us",
  link2 = "LLC Registration",
  socialLinkTitleCategory = "Connect With Us",
}) => {
  return (
    <footer className="footer-footer1 thq-section-padding">
      <div className="footer-max-width thq-section-max-width">
        <div className="footer-content">
          <div className="footer-newsletter">
            <img alt={logoAlt} src={logoSrc} className="footer-image1" />
            <span className="thq-body-small">
              Get helpful tips and information
            </span>
            <div className="footer-actions">
              <div className="footer-form">
                <div className="footer-container">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    className="footer-text-input thq-input"
                  />
                </div>
                <button className="thq-button-outline footer-button">
                  <span className="thq-body-small">{action1}</span>
                </button>
              </div>
              <span className="footer-content2 thq-body-small">{content2}</span>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-column1">
              <strong className="thq-body-large footer-column1-title">
                {column1Title}
              </strong>
              <div className="footer-footer-links1">
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link1}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link2}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link3}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link4}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link5}
                </a>
              </div>
            </div>
            <div className="footer-column2">
              <strong className="thq-body-large footer-column2-title">
                {column2Title}
              </strong>
              <div className="footer-footer-links2">
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link6}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link7}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link8}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link9}
                </a>
                <a
                  href="https://example.com"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="thq-body-small"
                >
                  {link10}
                </a>
              </div>
            </div>
            <div className="footer-column3">
              <strong className="thq-body-large footer-social-link1-title">
                {socialLinkTitleCategory}
              </strong>
              <div className="footer-social-links">
                <div className="footer-link14">
                  <FontAwesomeIcon
                    icon={faFacebook}
                    className="thq-icon-small"
                  />
                  <span className="thq-body-small">Facebook</span>
                </div>
                <div className="footer-link15">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    className="thq-icon-small"
                  />
                  <span className="thq-body-small">Instagram</span>
                </div>
                <div className="footer-link16">
                  <FontAwesomeIcon
                    icon={faTwitter}
                    className="thq-icon-small"
                  />
                  <span className="thq-body-small">X</span>
                </div>
                <div className="footer-link17">
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    className="thq-icon-small"
                  />
                  <span className="thq-body-small">LinkedIn</span>
                </div>
                <div className="footer-link18">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    className="thq-icon-small"
                  />
                  <span className="thq-body-small">Youtube</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-credits">
          <div className="thq-divider-horizontal"></div>
          <div className="footer-row">
            <span className="thq-body-small">{content3}</span>
            <div className="footer-footer-links3">
              <span className="thq-body-small">{privacyLink}</span>
              <span className="thq-body-small">{termsLink}</span>
              <span className="thq-body-small">{cookiesLink}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
